/**
 * Default date formatter, use everywhere you need to format timestamp`
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
 */
export function formatDate(timestampMs: number, locale = 'en-GB', options?: Intl.DateTimeFormatOptions) {
  const formatter = new Intl.DateTimeFormat(locale, {
    timeZone: 'UTC',
    ...options,
  });
  return formatter.format(new Date(timestampMs));
}

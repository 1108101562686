import { PlanFeature } from '@/modules/pricing/models/plan-feature';

export function sortFeatureList(features: PlanFeature[]): PlanFeature[] {
  return [...features].sort((a, b) => {
    if (a.credits || b.credits) {
      return a.credits && !b.credits ? -1 : 1;
    }

    if (a.amount || b.amount) {
      return a.amount && !b.amount ? -1 : 1;
    }

    return a.sort - b.sort;
  });
}

import { defaultFilter } from '@/modules/@core/functions/data-table/default-filter';
import { Header } from '@/modules/@core/models/header';
import { Row } from '@/modules/@core/models/row';

export function doesRowMatchFilter(row: Row, search: string, headers: Header[]) {
  const values = Object.values(row);
  const keys = Object.keys(row);
  let matched = false;
  for (let i = 0; i < values.length; i += 1) {
    const value = values[i];
    const key = keys[i];
    const header = headers.find((h) => h.key === key && h.filterable === true);
    if (header) {
      matched = header.filterFunction ? header.filterFunction(row, search) : defaultFilter(value, search, header);
      if (matched) {
        return true;
      }
    }
  }
  return false;
}

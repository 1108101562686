import { generateColor, generateRandomColor, getSeed } from '@/modules/@core/functions/generate-material-color';
import { AvatarGeneratorFunction } from '@/modules/@core/models/avatar-generator-function';

export function generateBackgroundColor(name: string, randomize = false): string {
  return randomize ? generateRandomColor(name) : generateColor(name);
}

export function generateAvatarSvgString(
  name: string,
  func: AvatarGeneratorFunction,
  randomize = false,
  widthPx = 512,
  heightPx = 512,
) {
  const seed = getSeed(name, randomize);

  return func(seed, {
    backgroundColor: generateBackgroundColor(name, randomize),
    width: `${widthPx}px`,
    height: `${heightPx}px`,
  });
}

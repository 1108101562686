import { GetTranslation } from '@/modules/translations/models/get-translation';
import { Locale } from '@/modules/translations/models/locale';
import { LocaleMessages } from '@/modules/translations/models/locale-messages';
import { get, isNumber, uniq } from 'lodash-es';
import { pluralizationRules } from '../const/pluralization-rules';

export class LanguageService {
  public fallbackLocales = ['en', 'xxa'];

  public currentLocale: string = this.fallbackLocales[0];

  public messages: Locale = {};

  protected loadedLanguages: string[] = [];

  public async lateInit() {
    await Promise.all(this.fallbackLocales.map((locale) => this.loadLanguageFile(locale)));
  }

  public async changeLocale(locale: string) {
    if (this.currentLocale === locale) {
      return;
    }

    if (this.loadedLanguages.includes(locale)) {
      this.currentLocale = locale;
      return;
    }

    await this.loadLanguageFile(locale);
    this.currentLocale = locale;
  }

  public t(key: string, variables: Record<string, any> = {}) {
    return this.inCurrentLocale({
      key,
      variables,
    });
  }

  public tc(key: string, count: number, variables: Record<string, any> = {}) {
    return this.inCurrentLocale({
      key,
      count,
      variables: {
        /** Automatically expand {count} into number */
        count,
        ...variables,
      },
    });
  }

  public async loadLanguageFile(locale: string) {
    let messages: LocaleMessages = {};
    switch (locale) {
      case 'en':
        messages = (await import('@/locales/en.json')).default;
        break;
      case 'cs':
        messages = (await import('@/locales/cs.json')).default;
        break;
      default:
        messages = (await import('@/locales/xxa.json')).default;
        break;
    }
    this.loadedLanguages = uniq([...this.loadedLanguages, locale]);
    this.messages[locale] = messages;
    return messages;
  }

  protected inCurrentLocale(data: Omit<GetTranslation, 'locale'>) {
    return this.getTranslation({
      ...data,
      locale: this.currentLocale,
    });
  }

  protected getTranslation(data: GetTranslation): string {
    const { locale, key, variables } = data;
    const usingFallbackLocale = this.currentLocale !== locale;
    const nextFallback = this.fallbackLocales[this.fallbackLocales.indexOf(locale) + 1];

    const messages = this.messages[locale] || {};
    let translation = get(messages, key, key) as string;
    if (isNumber(data.count)) {
      translation = this.resolvePluralCategory(translation, data.count, locale);
    }

    Object.entries(variables).forEach(([variable, value]) => {
      translation = translation.replace(`{${variable}}`, value);
    });

    if (translation === key) {
      if (usingFallbackLocale && !nextFallback) {
        // eslint-disable-next-line no-console
        console.error(`Translation key "${key}" not found in fallback`);
        translation = key;
      } else {
        // eslint-disable-next-line no-console
        console.warn(`Translation key "${key}" not found, falling back to ${nextFallback}`);
        translation = this.getTranslation({
          ...data,
          locale: nextFallback,
        });
      }
    }

    return translation;
  }

  private resolvePluralCategory(translation: string, count: number, locale: string) {
    const choices = translation.split('|');
    const choiceIndex = this.getChoiceIndex(count, choices.length, locale);
    if (!choices[choiceIndex]) {
      return translation;
    }
    return choices[choiceIndex].trim();
  }

  /** Copied from https://github.com/kazupon/vue-i18n/blob/v8.x/src/index.js#L141 */
  private getChoiceIndex(choice: number, choicesLength: number, locale: string) {
    if (pluralizationRules[locale] === undefined) {
      return pluralizationRules.fallback(choice, choicesLength);
    }
    return pluralizationRules[locale](choice, choicesLength);
  }
}

import { ExtendedVNode } from '@/modules/vue/models/extended-v-node';

import { unbind } from '@/modules/vue/functions/unbind';
import { IObserveVNodeDirective } from '@/modules/vue/models/i-observe-v-node-directive';
import { IntersectExtendedHtmlElement } from '@/modules/vue/models/intersect-extended-html-element';
import { VNode } from 'vue';

export function inserted(el: IntersectExtendedHtmlElement, binding: IObserveVNodeDirective, vnode: VNode) {
  if (typeof window === 'undefined' || !('IntersectionObserver' in window)) return;
  const extendedVNode = vnode as ExtendedVNode;

  const modifiers = binding.modifiers || {};
  const { value } = binding;
  const { handler, options } = typeof value === 'object' ? value : { handler: value, options: {} };

  const intersectionObserverCallback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
    const _observe = el._observe?.[extendedVNode.context?._uid];
    if (!_observe) return; // Just in case, should never fire

    const isIntersecting = entries.some((entry) => entry.isIntersecting);

    // If is not quiet or has already been
    // initted, invoke the user callback
    if (handler && (!modifiers.quiet || _observe.init) && (!modifiers.once || isIntersecting || _observe.init)) {
      handler(entries, observer, isIntersecting);
    }

    if (isIntersecting && modifiers.once) unbind(el, binding, extendedVNode);
    else _observe.init = true;
  };
  const intersectionObserver = new IntersectionObserver(intersectionObserverCallback, options);

  el._observe = Object(el._observe);

  el._observe![extendedVNode.context?._uid] = { init: false, observer: intersectionObserver };

  intersectionObserver.observe(el);
}

// Reference https://andrejgajdos.com/how-to-create-a-link-preview/

import { IOgData } from '@/modules/@core/models/i-og-data';

export async function getURLPreviewData(url: string): Promise<IOgData> {
  try {
    const rawData = await fetch(`https://directus-connector.localazy.com/embed/${encodeURIComponent(url)}`);
    const { result } = await rawData.json();
    return result;
  } catch {
    return {
      title: '',
      description: '',
      image: '',
      siteName: '',
      url,
    };
  }
}

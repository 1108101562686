export async function blobToPngDataUrl(url: string): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      // create a new image to hold it the converted type
      const img = new Image();

      // when the image is loaded we can get it as base64 url
      img.onload = () => {
        // create a canvas element to pass through
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        // draw it to the canvas
        if (ctx) {
          ctx.drawImage(img, 0, 0);
        }

        // we don't need the original anymore
        URL.revokeObjectURL(url);
        // now we can resolve the promise, passing the base64 url
        return resolve(canvas.toDataURL());
      };

      // load the image
      img.src = url;
    } catch (err: unknown) {
      reject(Error(`Failed to convert blob to png ${String(err)}`));
    }
  });
}

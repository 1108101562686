import { IApiSuccessResult } from '@/modules/@core/models/i-api-success-result';
import { EventEmitter } from '@/modules/@core/services/event-emitter';
import { UploadStatus } from '@/modules/upload/enums/upload-status';
import { IFileAnalysisResponse } from '@/modules/upload/models/i-file-analysis-response';
import { IFilesAnalysisRequest } from '@/modules/upload/models/i-files-analysis-request';
import { IUploadDetectResult } from '@/modules/upload/models/i-upload-detect-result';
import { IUploadFileModifyRequest } from '@/modules/upload/models/i-upload-file-modify-request';
import { IUploadFileRequest } from '@/modules/upload/models/i-upload-file-request';
import { IUploadFormat } from '@/modules/upload/models/i-upload-format';
import { IUploadPopulation } from '@/modules/upload/models/i-upload-population';
import { IUploadSession } from '@/modules/upload/models/i-upload-session';
import { IUploadSessionFiles } from '@/modules/upload/models/i-upload-session-files';
import { IUploadSessionRequest } from '@/modules/upload/models/i-upload-session-request';
import { IUploadSessionStatus } from '@/modules/upload/models/i-upload-session-status';
import { UploadHeaders } from '@/modules/upload/models/upload-headers';
import { CacheService } from '@localazy/core';
import { AxiosInstance, AxiosResponse } from 'axios';
import { Base64 } from 'js-base64';

export class UploadService extends EventEmitter {
  protected axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    super();
    this.axios = axios;
  }

  public async createUploadSession(payload: IUploadSessionRequest = {}, recaptchaToken = ''): Promise<string> {
    if (recaptchaToken !== '') {
      payload.token = recaptchaToken;
    }

    try {
      const result = await this.axios.post('/upload', payload);
      // returns token
      return result.data.result;
    } catch (e: any) {
      throw e.data;
    }
  }

  public async updateUploadSession(sessionId: string, payload: IUploadSessionRequest): Promise<IApiSuccessResult> {
    try {
      const { data }: AxiosResponse<IApiSuccessResult> = await this.axios.put(`/upload/${sessionId}`, payload);

      return data;
    } catch (e: any) {
      throw e.data;
    }
  }

  public async uploadFile(sessionId: string, payload: IUploadFileRequest) {
    try {
      // Returns ID of the newly created file
      return await this.uploadFilePromise(sessionId, payload);
    } catch (e: any) {
      throw e.data;
    }
  }

  public async modifyFile(
    sessionId: string,
    fileId: string,
    payload: IUploadFileModifyRequest,
  ): Promise<IApiSuccessResult> {
    try {
      const result: AxiosResponse<IApiSuccessResult> = await this.axios.put(
        `/upload/${sessionId}/file/${fileId}`,
        payload,
      );
      return result.data;
    } catch (e: any) {
      throw e.data;
    }
  }

  public async deleteFile(sessionId: string, fileId: string) {
    try {
      const result = await this.axios.delete(`/upload/${sessionId}/file/${fileId}`);
      return result.data;
    } catch (e: any) {
      throw e.data;
    }
  }

  public async fetchUploadSession(sessionId: string): Promise<IUploadSession> {
    try {
      const result = await this.axios.get(`/upload/${sessionId}`);
      return result.data;
    } catch (e: any) {
      throw e.data;
    }
  }

  public async applyUploadToProject(slug: string, sessionId: string) {
    try {
      const result = await this.axios.post(`/project/${slug}/upload/${sessionId}`, {});
      return result.data;
    } catch (e: any) {
      throw e.data;
    }
  }

  public async fetchFilesInSession(sessionId: string): Promise<IUploadSessionFiles> {
    try {
      const result = await this.axios.get(`/upload/${sessionId}/file`);
      return result.data;
    } catch (e: any) {
      throw e.data;
    }
  }

  public async fetchLanguagePopulations(): Promise<IUploadPopulation> {
    const FULL_PATH = '/upload/populations';
    const cached = CacheService.get(FULL_PATH);
    if (!cached) {
      try {
        const result = await this.axios.get(FULL_PATH);
        CacheService.set(FULL_PATH, result.data);
        return result.data;
      } catch (e: any) {
        throw e.data || e;
      }
    } else {
      return cached as IUploadPopulation;
    }
  }

  public async fetchUploadFormats(locale?: string): Promise<IUploadFormat> {
    let FULL_PATH = '/upload/formats';
    if (locale) {
      FULL_PATH += `?locale=${locale}`;
    }
    const cached = CacheService.get(FULL_PATH);
    if (!cached) {
      try {
        const result = await this.axios.get(FULL_PATH);
        CacheService.set(FULL_PATH, result.data);
        return result.data;
      } catch (e: any) {
        throw e.data || e;
      }
    } else {
      return cached as IUploadFormat;
    }
  }

  public async fetchFileRecommendation(fileName: string, filePath = ''): Promise<IUploadDetectResult> {
    const headers = {
      'x-localazy-b64-file': Base64.encode(fileName),
      'x-localazy-b64-path': Base64.encode(filePath),
    };

    try {
      const result = await this.axios.get('/upload/detect', { headers });
      return result.data;
    } catch (e: any) {
      throw e.data;
    }
  }

  public async analyzeFiles(sessionToken: string, request: IFilesAnalysisRequest): Promise<IFileAnalysisResponse[]> {
    try {
      const { data }: AxiosResponse<IFileAnalysisResponse[]> = await this.axios.post(
        '/upload/content-analysis',
        request,
      );

      return data;
    } catch (e: any) {
      throw e.data;
    }
  }

  protected async uploadFilePromise(sessionId: string, payload: IUploadFileRequest): Promise<string> {
    try {
      const headers: UploadHeaders = {
        'content-type': 'application/octet-stream',
        'x-localazy-b64-name': Base64.encode(payload.fileName),
        'x-localazy-type': payload.fileType,
        'x-localazy-lang': payload.langId,
      };

      if (payload.path) {
        headers['x-localazy-b64-param-path'] = Base64.encode(payload.path);
      }

      if (payload.paramAnalysis) {
        headers['x-localazy-param-analysis'] = payload.paramAnalysis;
      }

      if (payload.params) {
        Object.entries(payload.params).forEach((entry) => {
          const [name, value] = entry;
          headers[`x-localazy-b64-param-${name}`] = Base64.encode(String(value));
        });
      }

      const fileBuffer = await payload.file.arrayBuffer();
      const result = await this.axios.post(`/upload/${sessionId}/file`, fileBuffer, {
        headers,
      });
      return result.data.result;
    } catch (e: any) {
      throw e.data;
    }
  }

  public async getUploadSessionStatus(slug: string, sessionToken: string): Promise<IUploadSessionStatus> {
    const response: AxiosResponse<IUploadSessionStatus> = await this.axios.get(
      `/project/${slug}/upload/${sessionToken}`,
    );

    return {
      ...response.data,
      isUploadProcessed:
        response.status === 200 && ![UploadStatus.SCHEDULED, UploadStatus.IN_PROGRESS].includes(response.data.status),
    };
  }
}

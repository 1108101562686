import { ClickOutsideExtendedHtmlElement } from '@/modules/@core/models/click-outside-extended-html-element';

export function removeClickOutsideListener(el: HTMLElement) {
  const handledEl = el as ClickOutsideExtendedHtmlElement;

  if (!handledEl._clickOutside) return;

  const app = document.querySelector('#app') || document.body; // This is only for unit tests
  if (app) {
    app.removeEventListener('mousedown', handledEl._clickOutside, true);
  }

  delete handledEl._clickOutside;
}

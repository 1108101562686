/**
 * Format amount according to passed locale and currency
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
 */
export function formatCurrency(
  value: number,
  options: Intl.NumberFormatOptions = {},
  locale = 'en-US',
  currency = 'USD',
) {
  return new Intl.NumberFormat(locale, { style: 'currency', currency, ...options }).format(value);
}

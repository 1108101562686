/* Reference: https://github.com/gmfmi/searchinGhost */
import { EnvService } from '@/modules/@core/services/env-service';
import { dateOptions, locale } from '@/modules/translations/const/date-format';
import SearchinGhost from '@localazy/searchinghost';

export function searchInGhostFactory() {
  return new SearchinGhost({
    key: EnvService.getGhostContentApi(),
    url: EnvService.getGhostUrl(),
    inputId: [],
    outputId: [],
    limit: 20,
    searchOn: 'none',
    loadOn: 'none',
    cacheMaxAge: EnvService.isDevelopmentNodeEnv() ? 0 : 1800,
    date: {
      locale,
      options: dateOptions,
    },
    postsFields: [
      'title',
      'slug',
      'excerpt',
      'custom_excerpt',
      'published_at',
      'feature_image',
      'featured',
      'primary_author',
    ],
    postsExtraFields: ['tags', 'authors'],
    postsFormats: ['html'],
    indexedFields: ['title', 'string_tags', 'excerpt', 'html'],
    debug: EnvService.isDevelopmentNodeEnv(),
  });
}

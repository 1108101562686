import { generateChildren } from '@/modules/@core/functions/generate-children';
import { generateSlots } from '@/modules/@core/functions/generate-slots';

/**
 * USAGE
 * You need to define the component from within the story,
 * the rest can be dynamically attached
 *
 * export const Template = (args, options) => {
 * return {
 * ...mdxTemplate(args, options),
 * components: {LocSelect}
 *
 * @param {*} args Storybook args
 * @param {*} options Storybook options
 * @param additionalOptions
 */
export function Template(args, options, additionalOptions = { componentName: '', wrapperClasses: '' }) {
  const { argTypes, kind } = options;
  const name = additionalOptions.componentName || kind;
  const classes = additionalOptions.wrapperClasses || '';
  return {
    name: `Wrapper-${name}`,
    props: Object.keys(argTypes),
    template: `
      <${name} v-bind="$props" class="${classes}">
        ${generateSlots(args.slots)}
        ${generateChildren(args.children)}
      </${name}>
    `,
  };
}

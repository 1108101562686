import { getAttribute } from '@/modules/@core/functions/utils/get-attribute';
import { itemInArray } from '@/modules/@core/functions/utils/item-in-array';

/**
 * @deprecated This function is slow on large collection and needs to be optimized
 * Combine items present in both arrays
 * The items only need to share a common attribute, they don't need to be identical
 * Formally arr1 - arr2 => exists in arr1 & in arr2
 * @param arr1 first array
 * @param arr1Attr attribute of first array item type for comparison
 * @param arr2 second array
 * @param arr2Attr attribute of second array item type for comparison
 */
export function arrayIntersection<T1, T2>(arr1: T1[], arr1Attr: keyof T1, arr2: T2[], arr2Attr: keyof T2): T1[] {
  return arr1.filter((item1) => itemInArray<T2>(getAttribute(item1, arr1Attr), arr2, arr2Attr));
}

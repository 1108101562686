import { formatCurrency } from '@/modules/@core/functions/utils/format/format-currency';
import { PayPriceInterval } from '@/modules/pricing/models/i-pay-price';
import { IPayProduct } from '@/modules/pricing/models/i-pay-product';

export class PlanDataFeedService {
  /** Use lowest price in price options */
  public static resolvePlanPriceForFeed(data: { plan: IPayProduct; interval: PayPriceInterval }): string {
    const planPrices = data.plan.prices.filter((price) => price.interval === data.interval).map((p) => p.unitAmount);
    return formatCurrency(Math.min(...planPrices) / 100, { minimumFractionDigits: 2, currencyDisplay: 'code' });
  }
}

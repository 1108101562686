import { clickOutsideElement } from '@/modules/@core/functions/click-outside-element';
import { ClickOutsideExtendedHtmlElement } from '@/modules/@core/models/click-outside-extended-html-element';
import { IClickOutsideBindingArgs } from '@/modules/@core/models/i-click-outside-binding-args';

export function attachClickOutside(el: HTMLElement, binding: IClickOutsideBindingArgs) {
  const onClick = (e: Event) => clickOutsideElement(e as PointerEvent, el, binding);
  // iOS does not recognize click events on document or body
  const app = document.querySelector('#app') || document.body; // This is only for unit tests
  app.addEventListener('mousedown', onClick, true);

  (el as ClickOutsideExtendedHtmlElement)._clickOutside = onClick;
}

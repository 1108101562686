import { doesRowMatchFilter } from '@/modules/@core/functions/data-table/does-row-match-filter';
import { Header } from '@/modules/@core/models/header';
import { Row } from '@/modules/@core/models/row';

export function locDataTableFilterRows(rows: Row[], search: string, headers: Header[]) {
  const filteredRows: Row[] = [];
  rows.forEach((row) => {
    if (doesRowMatchFilter(row, search, headers)) {
      filteredRows.push(row);
    }
  });
  return filteredRows;
}

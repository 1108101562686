// REFERENCES
// https://markdown-it.github.io/markdown-it/#Renderer.renderToken
// https://www.npmjs.com/package/markdown-it-include?activeTab=readme - how to use env
// https://github.com/junkawa/markdown-it-link-preview - based on

import { IOgData } from '@/modules/@core/models/i-og-data';
import { IUrlPreviewOptions } from '@/modules/@core/models/i-url-preview-options';
import MarkdownIt from 'markdown-it';
import Renderer from 'markdown-it/lib/renderer.js';
import Token from 'markdown-it/lib/token.js';

function linkPreviewHtml(ogData: IOgData) {
  // Empty title suggests unsuccessful fetch
  if (ogData.title === '' || ogData.description === '' || ogData.image === '') {
    return '';
  }

  return `
    <a href="${ogData.url}" rel="noopener" target="_blank" class="link-preview-widget">
        <span
        style="background-image: url('${ogData.image}');"
        class="link-preview-widget-image"></span>
        <span class="link-preview-widget-texts">
          <span>
            <span class="link-preview-widget-title">
                ${ogData.title}
            </span>
            <span class="link-preview-widget-description">
                ${ogData.description}
            </span>
          </span>
          <span class="link-preview-widget-url">
              ${ogData.siteName}
          </span>
        </span>
    </a>
  `;
}

function isLinkPreview(token: Token) {
  if (token.type === 'text' && token.content.includes('@preview')) {
    return true;
  }
  return false;
}

function isInlinePreview(token: Token) {
  if (isLinkPreview(token) && token.content.includes('@preview:')) {
    return true;
  }
  return false;
}

function getHref(token: Token) {
  if (token.type === 'link_open') {
    return token.attrGet('href') || '';
  }
  return '';
}

function defaultRenderer(
  tokens: Token[],
  idx: number,
  opts: MarkdownIt.Options,

  env: Record<string, unknown>,
  self: Renderer,
) {
  return self.renderToken(tokens, idx, opts);
}

function appendPreviewLinkTokens(tokens: Token[]) {
  const linkOpenToken = tokens.find((t) => t.type === 'link_open');
  if (linkOpenToken) {
    const url = getHref(linkOpenToken);
    // Will be hidden when rendered, but we need its url for later
    linkOpenToken.attrSet('data-hidden', 'true');

    const previewLinkOpen = new Token('link_open', 'a', 1);
    previewLinkOpen.attrSet('href', url);
    previewLinkOpen.attrSet('target', '_blank');
    previewLinkOpen.attrSet('previewLink', 'true');

    const previewLinkText = new Token('text', '', 0);
    previewLinkText.content = '@preview';

    tokens.push(previewLinkOpen);
    tokens.push(previewLinkText);
    tokens.push(new Token('link_close', 'a', -1));
  }
}

export function linkPreviewPlugin(md: MarkdownIt, options: IUrlPreviewOptions) {
  const defaultRender = md.renderer.rules.link_open || defaultRenderer;

  const defaultTextRenderer = md.renderer.rules.text || defaultRenderer;

  md.renderer.rules.text = (tokens, idx, opts, env, self) => {
    if (!options.enabled) {
      return defaultTextRenderer(tokens, idx, opts, env, self);
    }

    const textToken = tokens[idx];
    if (isInlinePreview(textToken)) {
      appendPreviewLinkTokens(tokens);
      const linkWithoutPreviewPrefix = textToken.content.substr(textToken.content.indexOf(':') + 1);
      return linkWithoutPreviewPrefix;
    }

    // To prevent showing '@preview' while loading
    if (isLinkPreview(textToken)) {
      return '';
    }

    return defaultTextRenderer(tokens, idx, opts, env, self);
  };

  md.renderer.rules.link_open = (tokens, idx, opts, env, self) => {
    if (!options.enabled) {
      return defaultRenderer(tokens, idx, opts, env, self);
    }

    const textToken = tokens[idx + 1];
    const linkOpenToken = tokens[idx];

    if (isLinkPreview(textToken)) {
      const url = getHref(linkOpenToken);
      const isVisibleToken = linkOpenToken.attrGet('data-hidden') !== 'true';

      if (env[url] && isVisibleToken) {
        return linkPreviewHtml(env[url]);
      }
      if (!isInlinePreview(textToken)) {
        return '<div class="link-preview-widget-placeholder"></div>';
      }
    }

    return defaultRender(tokens, idx, opts, env, self);
  };
}

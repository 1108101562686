import { linkPreviewPlugin } from '@/modules/@core/functions/link-preview-plugin';
import { markdownItHighlight } from '@/modules/@core/functions/markdown-it-highlight';
import { markdownitLinkAttributes } from '@/modules/@core/functions/markdown-it-link-attributes';
import { DeepPartial } from '@/modules/@core/models/deep-partial';
import { MarkdownItCompiler } from '@localazy/markdown-it-compiler';
import MarkdownIt from 'markdown-it';
import MarkdownItAnchor from 'markdown-it-anchor';
import MarkdownItAttrs from 'markdown-it-attrs';
import MarkdownItContainer from 'markdown-it-container';
import MarkdownItEmoji from 'markdown-it-emoji/light.js';
import Token from 'markdown-it/lib/token.js';
import slugify from 'slugify';
import twemoji from 'twemoji';

const DEFAULT_OPTIONS_LINK_ATTRIBUTES = {};

export const markdownItDefaultOptions = {
  markdownIt: {
    linkify: true,
    html: true,
    typographer: true,
  },
  linkAttributes: DEFAULT_OPTIONS_LINK_ATTRIBUTES,
  headerAnchor: {
    level: [2, 3],
    permalink(slug, opts, state, idx) {
      // Remove id attribute from headline
      const index: number = state.tokens[idx]?.attrs?.findIndex((i: string[]): boolean => i[0] === 'id');
      if (index > -1) {
        state.tokens[idx]?.attrs.splice(index, 1);
      }

      // Render permalink
      MarkdownItAnchor.permalink.linkInsideHeader({
        class: opts.permalinkClass,
        symbol: opts.permalinkSymbol,
      })(slug, opts, state, idx);

      // Render target anchor with id attribute
      const linkTokens = [
        Object.assign(new state.Token('link_open', 'a', 1), {
          attrs: [
            ['class', 'markdownit-header-anchor__offset'],
            ['id', slug],
            ['tabindex', '-1'],
            ['href', '#'],
          ],
        }),
        new state.Token('link_close', 'a', -1),
      ];
      state.tokens[idx + 1].children.push(...linkTokens);
    },
    permalinkClass: 'markdownit-header-anchor',
    permalinkSymbol: '#️⃣',
    permalinkBefore: false,
    slugify: (str: string) => slugify(str, { lower: true, strict: true }),
  },
  urlPreview: {
    enabled: true,
  },
  markdownItAttrs: {
    leftDelimiter: '{',
    rightDelimiter: '}',
    allowedAttributes: [],
  },
};

export function markdownIt(options: DeepPartial<typeof markdownItDefaultOptions> = markdownItDefaultOptions) {
  const optMarkdownIt = { ...markdownItDefaultOptions.markdownIt, ...options.markdownIt };
  const linkAttributes = { ...markdownItDefaultOptions.linkAttributes, ...options.linkAttributes };
  const urlPreviewOptions = { ...markdownItDefaultOptions.urlPreview, ...options.urlPreview };
  const headerAnchorOptions = { ...markdownItDefaultOptions.headerAnchor, ...options.headerAnchor };
  const markdownItAttrsOptions = { ...markdownItDefaultOptions.markdownItAttrs, ...options.markdownItAttrs };
  const md = new MarkdownIt(optMarkdownIt)
    .use(MarkdownItCompiler)
    .use(MarkdownItEmoji)
    .use(markdownItHighlight)
    .use(markdownitLinkAttributes, linkAttributes)
    .use(linkPreviewPlugin, urlPreviewOptions)
    .use(MarkdownItAnchor, headerAnchorOptions)
    .use(MarkdownItAttrs, markdownItAttrsOptions)
    .use(MarkdownItContainer, 'warning', {
      validate(params: string) {
        return params.trim() === 'warning';
      },
      render: (tokens: Token[], idx: number) => {
        if (tokens[idx].nesting === 1) {
          const icon =
            '<i class="markdown-it-vue-alert-icon markdown-it-vue-alert-icon-warning"><svg viewBox="64 64 896 896" data-icon="exclamation-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" class=""><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296zm32 440a48.01 48.01 0 0 1 0-96 48.01 48.01 0 0 1 0 96z"></path></svg></i>';
          return `<div class="markdown-it-vue-alter markdown-it-vue-alter-warning">${icon}`;
        }
        return '</div>';
      },
    })
    .use(MarkdownItContainer, 'info', {
      validate(params: string) {
        return params.trim() === 'info';
      },
      render: (tokens: Token[], idx: number) => {
        if (tokens[idx].nesting === 1) {
          const icon =
            '<i class="markdown-it-vue-alert-icon markdown-it-vue-alert-icon-info"><svg viewBox="64 64 896 896" data-icon="info-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" class=""><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm32 664c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V456c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272zm-32-344a48.01 48.01 0 0 1 0-96 48.01 48.01 0 0 1 0 96z"></path></svg></i>';
          return `<div class="markdown-it-vue-alter markdown-it-vue-alter-info">${icon}`;
        }
        return '</div>';
      },
    })
    .use(MarkdownItContainer, 'success', {
      validate(params: string) {
        return params.trim() === 'success';
      },
      render: (tokens: Token[], idx: number) => {
        if (tokens[idx].nesting === 1) {
          const icon =
            '<i class="markdown-it-vue-alert-icon markdown-it-vue-alert-icon-success"><svg viewBox="64 64 896 896" data-icon="check-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" class=""><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 0 1-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path></svg></i>';
          return `<div class="markdown-it-vue-alter markdown-it-vue-alter-success">${icon}`;
        }
        return '</div>';
      },
    })
    .use(MarkdownItContainer, 'error', {
      validate(params: string) {
        return params.trim() === 'error';
      },
      render: (tokens: Token[], idx: number) => {
        if (tokens[idx].nesting === 1) {
          const icon =
            '<i class="markdown-it-vue-alert-icon markdown-it-vue-alert-icon-error"><svg viewBox="64 64 896 896" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" class=""><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 0 1-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path></svg></i>';
          return `<div class="markdown-it-vue-alter markdown-it-vue-alter-error">${icon}`;
        }
        return '</div>';
      },
    });

  md.renderer.rules.emoji = (token, idx) => twemoji.parse(token[idx].content);

  return md;
}

import { formatCurrency } from '@/modules/@core/functions/utils/format/format-currency';
import { formatNumber } from '@/modules/@core/functions/utils/format/format-number';
import { langService } from '@/modules/translations/const/lang-service';
import { GetFeaturesForPlanOptions } from '@/modules/pricing/models/get-features-for-plan-options';
import { GetFeaturesForPlanParams } from '@/modules/pricing/models/get-features-for-plan-params';
import { GetSourceKeyItemsParams } from '@/modules/pricing/models/get-source-key-items-params';
import { IPayPrice } from '@/modules/pricing/models/i-pay-price';
import { IPayProduct } from '@/modules/pricing/models/i-pay-product';
import { IPaySubscription } from '@/modules/pricing/models/i-pay-subscription';
import { PlanFeature } from '@/modules/pricing/models/plan-feature';
import { PriceOptions } from '@/modules/pricing/models/price-options';
import { ProductWithApiPropertyMetadata } from '@/modules/pricing/models/product-with-api-property-metadata';
import { PricingPlan, PricingPlanFeature } from '@localazy/directus-service';

export class PlanDataFeaturesService {
  public static getFeaturesForPlan(
    params: GetFeaturesForPlanParams,
    options: GetFeaturesForPlanOptions = {},
  ): PlanFeature[] {
    const {
      pricingPlanFeatures,
      directusPricingPlans,
      planName,
      stripeProduct,
      isAnnual,
      activeSubscription,
      activeSubscriptionPayPrice,
    } = params;
    let features: PlanFeature[] = this.getBaseFeaturesForPlan(pricingPlanFeatures, planName);

    features.push(
      this.getSourceKeyFeature(planName, stripeProduct, activeSubscription, isAnnual, activeSubscriptionPayPrice),
    );

    if (!options.skipCreditFeatures) {
      features = [...features, ...this.getCreditFeaturesIfExists(stripeProduct, isAnnual)];
    }
    features = [...features, ...this.getIncludedPlanIfExists(directusPricingPlans, planName)];

    return features;
  }

  public static getAllFeaturesForPlan(
    pricingPlanFeatures: PricingPlanFeature[],
    directusPricingPlans: PricingPlan[],
    planName: string,
    stripeProduct: IPayProduct | undefined,
    isAnnual = false,
    options: GetFeaturesForPlanOptions = {},
  ) {
    let plan = directusPricingPlans.find((p) => p.stripe_name === planName);
    let features: PlanFeature[] = this.getBaseFeaturesForPlan(pricingPlanFeatures, planName);
    while (plan?.includes_plan) {
      features = [...features, ...this.getBaseFeaturesForPlan(pricingPlanFeatures, plan.includes_plan.stripe_name)];

      plan = directusPricingPlans.find((p) => p.stripe_name === plan?.includes_plan?.stripe_name);
    }
    features.push(this.getSourceKeyFeature(planName, stripeProduct, undefined, isAnnual));

    if (!options.skipCreditFeatures) {
      features = [...features, ...this.getCreditFeaturesIfExists(stripeProduct, isAnnual)];
    }

    return features;
  }

  protected static getBaseFeaturesForPlan(pricingPlanFeatures: PricingPlanFeature[], planName: string) {
    const features: PlanFeature[] = pricingPlanFeatures
      .filter((planFeature) => planFeature.pricing_plan.stripe_name === planName)
      .map((planFeature) => ({
        name: planFeature.feature.name,
        alternativeName: planFeature.feature.alternative_name || planFeature.feature.name,
        isReleased: planFeature.feature.is_released,
        isBeta: planFeature.feature.is_beta,
        isPopular: planFeature.feature.is_popular,
        sort: planFeature.sort,
        comparisonSort: planFeature.comparison_sort,
        showInPreview: planFeature.show_in_preview,
        showInPlanPage: planFeature.show_in_plan_page,
        description: planFeature.feature.longer_description || '',
        alternativeDescription: planFeature.feature.alternative_description || '',
        value: planFeature.feature.value || '',
        featureSection: {
          name: planFeature.feature_section?.name || langService.t('planDataFeaturesService.other'),
          comparisonName:
            planFeature.feature_section?.comparison_name ||
            planFeature.feature_section?.name ||
            langService.t('planDataFeaturesService.other'),
          sort: planFeature.feature_section?.sort || Number.MAX_SAFE_INTEGER,
        },
        link: planFeature.feature.docs_link || '',
      }));

    return features;
  }

  protected static getSourceKeyFeature(
    planName: string,
    stripeProduct?: IPayProduct,
    activeSubscription?: IPaySubscription,
    isAnnual = false,
    activeSubscriptionPayPrice?: IPayPrice,
  ) {
    const isEnterprise = planName === 'enterprise';
    return {
      name: langService.t('planDataFeaturesService.source_keys'),
      alternativeName: langService.t('planDataFeaturesService.source_keys_alternative_name'),
      isReleased: true,
      isBeta: false,
      isPopular: false,
      isSourceKeys: true,
      amount: !isEnterprise ? this.getSourceKeyCount(stripeProduct) : -1,
      sort: 0,
      comparisonSort: 0,
      showInPreview: true,
      showInPlanPage: true,
      description: langService.t('planDataFeaturesService.source_key_feature_description'),
      alternativeDescription: langService.t('planDataFeaturesService.source_key_feature_alternative_description'),
      value: !isEnterprise
        ? langService.tc(
            'planDataFeaturesService.x_source_keys',
            this.getSourceKeyCount(stripeProduct, activeSubscription),
            {
              count: formatNumber(this.getSourceKeyCount(stripeProduct, activeSubscription)),
            },
          )
        : langService.t('planDataFeaturesService.custom_source_keys'),
      featureSection: {
        name: langService.t('planDataFeaturesService.source_key_feature_section'),
        comparisonName: langService.t('planDataFeaturesService.source_key_feature_section'),
        sort: 1,
      },
      link: 'https://localazy.com/faq/pricing/what-are-source-keys',
      priceOptions: !isEnterprise
        ? this.getSourceKeyItems({
            stripeProduct,
            activeSubscriptionPayPrice,
            isAnnual,
          })
        : undefined,
    };
  }

  public static getSourceKeyItems(params: GetSourceKeyItemsParams): PriceOptions[] {
    const { stripeProduct, activeSubscriptionPayPrice, isAnnual } = params;
    const activeKeyMeta = this.findApiProperty(activeSubscriptionPayPrice, 'phrases');
    const activeKeysValue = activeKeyMeta ? +activeKeyMeta.split(':')[1] + 200 : 200;
    const priceOptions: PriceOptions[] = [];
    if (stripeProduct) {
      stripeProduct.prices
        .filter((p) => (isAnnual ? p.interval === 'year' : p.interval === 'month'))
        .forEach((price) => {
          const sourceKeysMeta = this.findApiProperty(price, 'phrases');
          if (sourceKeysMeta) {
            const keysValue = +sourceKeysMeta.split(':')[1] + 200;
            priceOptions.push({
              value: keysValue,
              label: langService.tc('planDataFeaturesService.x_source_keys', keysValue, {
                count: formatNumber(keysValue),
              }),
              priceId: price.id,
              unitAmount: price.unitAmount,
              disabled: keysValue < activeKeysValue,
            });
          }
        });
    }
    return priceOptions.sort((a, b) => a.value - b.value);
  }

  public static getSourceKeyCount(stripeProduct?: IPayProduct, activeSubscription?: IPaySubscription) {
    // Use real subscribed amount if exists
    if (stripeProduct) {
      const sourceKeysMeta =
        activeSubscription && (activeSubscription?.productIds || []).includes(stripeProduct.id)
          ? activeSubscription.bundleItems.find((item) => item.includes('phrases'))
          : this.findApiProperty(stripeProduct, 'phrases');

      if (sourceKeysMeta) {
        return +sourceKeysMeta.split(':')[1] + 200;
      }
    }
    return 200;
  }

  protected static getCreditFeaturesIfExists(stripeProduct?: IPayProduct, isAnnual = false) {
    const features: PlanFeature[] = [];
    const vtCreditsMeta = this.findApiProperty(stripeProduct, 'virtualContributor');
    if (vtCreditsMeta && isAnnual) {
      const credits = +vtCreditsMeta.split(':')[1];
      features.push({
        name: langService.t('planDataFeaturesService.translation_credits'),
        alternativeName: langService.t('planDataFeaturesService.translation_credits'),
        isReleased: true,
        isBeta: false,
        isPopular: false,
        credits: credits * 10,
        sort: 1,
        comparisonSort: 1,
        showInPreview: true,
        showInPlanPage: true,
        description: langService.t('planDataFeaturesService.translation_credits_description'),
        alternativeDescription: langService.t('planDataFeaturesService.translation_credits_alternative_description'),
        value: langService.t('planDataFeaturesService.translation_credits_per_year', {
          count: formatCurrency(credits * 10),
        }),
        featureSection: {
          name: langService.t('planDataFeaturesService.translation_credits_feature_section'),
          comparisonName: langService.t('planDataFeaturesService.translation_credits_feature_section'),
          sort: Number.MAX_SAFE_INTEGER,
        },
        link: 'https://localazy.com/docs/general/virtual-translator',
      });
    }

    return features;
  }

  protected static getIncludedPlanIfExists(directusPricingPlans: PricingPlan[], planName: string) {
    const features: PlanFeature[] = [];
    const plan = directusPricingPlans.find((p) => p.stripe_name === planName);
    if (plan?.includes_plan) {
      features.push({
        name: langService.t('planDataFeaturesService.included_plan_name', { planName: plan.includes_plan.name }),
        alternativeName: langService.t('planDataFeaturesService.included_plan_name', {
          planName: plan.includes_plan.name,
        }),
        includesAllInPlan: plan.includes_plan.name,
        isReleased: true,
        isBeta: false,
        isPopular: false,
        showInPlanPage: false,
        sort: Number.MAX_SAFE_INTEGER,
        comparisonSort: 0,
        showInPreview: true,
        featureSection: {
          name: langService.t('planDataFeaturesService.other'),
          comparisonName: langService.t('planDataFeaturesService.other'),
          sort: Number.MAX_SAFE_INTEGER,
        },
      });
    }
    return features;
  }

  public static findApiProperty(product: ProductWithApiPropertyMetadata | undefined, property: string): string {
    if (product) {
      return product.metadata.api_property?.split(',').find((prop) => prop.includes(property)) || '';
    }
    return '';
  }

  public static findApiPropertyValue(product: ProductWithApiPropertyMetadata | undefined, property: string): string {
    const apiProperty = this.findApiProperty(product, property);
    return apiProperty ? apiProperty.split(':')[1] : '';
  }

  public static findPhrasesApiPropertyValue(product: ProductWithApiPropertyMetadata | undefined): number {
    return +this.findApiPropertyValue(product, 'phrases');
  }

  public static findPhrasesApiPropertyValueTotal(product: ProductWithApiPropertyMetadata | undefined): number {
    return +this.findPhrasesApiPropertyValue(product) + 200;
  }
}

import { isNumber } from '@/modules/@core/functions/data-table/is-number';
import { isString } from '@/modules/@core/functions/data-table/is-string';
import { Header } from '@/modules/@core/models/header';
import { get } from 'lodash-es';

export function defaultFilter(value: unknown, search: string, header: Header): boolean {
  let filterAttribute = value;
  if (header.filterAttribute) {
    filterAttribute = get(value, header.filterAttribute);
  }
  if (isString(filterAttribute)) {
    return filterAttribute.toLowerCase().includes(search.toLowerCase());
  }
  if (isNumber(filterAttribute)) {
    return filterAttribute.toString().toLowerCase().includes(search.toLowerCase());
  }
  return false;
}

import { defaultConditional } from '@/modules/@core/functions/default-conditional';
import { IClickOutsideBindingArgs } from '@/modules/@core/models/i-click-outside-binding-args';

export function clickOutsideElement(e: PointerEvent, el: HTMLElement, binding: IClickOutsideBindingArgs): void {
  const { handler } = binding;
  const isActive = binding.closeConditional || defaultConditional;

  // The include element callbacks below can be expensive
  // so we should avoid calling them when we're not active.
  // Explicitly check for false to allow fallback compatibility
  // with non-toggleable components
  if (!e || !isActive(e)) return;

  // Check if additional elements were passed to be included in check
  // (click must be outside all included elements, if any)
  const elements = ((binding.include && binding.include) || (() => []))();
  // Add the root element for the component this directive was defined on
  elements.push(el);

  // Check if it's a click outside our elements, and then if our callback returns true.
  // Non-toggleable components should take action in their callback and return falsy.
  // Toggleable can return true if it wants to deactivate.
  // Note that, because we're in the capture phase, this callback will occur before
  // the bubbling click event on any outside elements.
  if (!elements.some((elem) => elem.contains(e.target as Node))) {
    setTimeout(() => {
      if (isActive(e) && handler) {
        handler(e);
      }
    }, 0);
  }
}

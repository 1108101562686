import { formatCurrency } from '@/modules/@core/functions/utils/format/format-currency';
import { IPayPrice, PayPriceInterval } from '@/modules/pricing/models/i-pay-price';
import { IPayProduct } from '@/modules/pricing/models/i-pay-product';

export class PlanDataPriceService {
  public static resolveSelectedPrice(data: {
    plan: IPayProduct;
    interval: PayPriceInterval;
    priceId?: string;
  }): IPayPrice {
    return data.plan?.prices.find((price) => {
      if (data.priceId) {
        return price.id === data.priceId;
      }
      return price.interval === data.interval;
    }) as IPayPrice;
  }

  public static resolvePlanPriceRaw(data: { price: IPayPrice; discount?: number; perMonth?: boolean }) {
    const discount = data.discount || 0;
    let price = (data.price.unitAmount - discount) / 100;
    if (data.perMonth === true && data.price.interval === 'year') {
      // convert to monthly price
      price /= 12;
    }
    return price;
  }

  public static resolvePlanPrice(data: {
    price: IPayPrice;
    discount?: number;
    perMonth?: boolean;
    formatOptions?: Intl.NumberFormatOptions;
  }): string {
    const price = this.resolvePlanPriceRaw(data);
    return this.getFormattedPrice({ price, options: data.formatOptions });
  }

  /**
   * When calculating with discount included, discounted price must be passed in `price` and `priceMonthly` parameters.
   * @returns formatted savings
   */
  public static resolveSavings(data: {
    price: IPayPrice;
    priceMonthly: IPayPrice;
    formatOptions?: Intl.NumberFormatOptions;
  }): string {
    if (data.price.interval !== 'year') {
      return this.getFormattedPrice({ price: 0, options: data.formatOptions });
    }

    let localPricePerMonth = data.price.unitAmount / 100 / 12;
    let planPricePerMonth = data.priceMonthly.unitAmount / 100;
    if (data.formatOptions?.maximumFractionDigits === 0) {
      // removes rounding error in the UI
      localPricePerMonth = Math.ceil(localPricePerMonth);
      planPricePerMonth = Math.ceil(planPricePerMonth);
    }
    const savings = (planPricePerMonth - localPricePerMonth) * 12;
    return this.getFormattedPrice({ price: savings, options: data.formatOptions });
  }

  public static getFormattedPrice(data: { price: number; options?: Intl.NumberFormatOptions }): string {
    let { price } = data;
    if (data.options?.maximumFractionDigits === 0) {
      price = Math.ceil(price);
    }
    const formatOptions = data.options || { minimumFractionDigits: price !== Math.round(price) ? 2 : 0 };
    return formatCurrency(price, formatOptions);
  }
}

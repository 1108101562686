import { getURLPreviewData } from '@/modules/@core/functions/fetch-page';
import { cloneDeep } from 'lodash-es';
import type Token from 'markdown-it/lib/token.js';

function isLinkPreview(token: Token): boolean {
  return token.type === 'inline' && token.content.includes('@preview');
}

function getHref(token: Token): string {
  if (!token.children) {
    return '';
  }
  const linkOpenToken = token.children.find((t) => t.type === 'link_open');

  if (linkOpenToken) {
    return linkOpenToken.attrGet('href') || '';
  }
  return '';
}

export async function addUrlsToEnv(tokens: Token[], env: Record<string, unknown>) {
  const linkPreviewExists = tokens.some(isLinkPreview);
  if (!linkPreviewExists) {
    return {
      modifiedEnv: false,
      env,
    };
  }

  const updatedEnv = cloneDeep(env);
  await Promise.all(
    tokens.map(async (token) => {
      if (isLinkPreview(token)) {
        const url = getHref(token);
        updatedEnv[url] = await getURLPreviewData(url);
      }
    }),
  );

  return {
    modifiedEnv: true,
    env: updatedEnv,
  };
}

export class CopyToClipBoardService {
  protected static copyIconClass = '.code-badge-copy-icon';

  protected static copiedIconClass = '.code-badge-copied-icon';

  public static attach(wrapperEl: HTMLElement) {
    wrapperEl.addEventListener('click', CopyToClipBoardService.listenerCallback);
  }

  public static detach(wrapperEl: HTMLElement) {
    wrapperEl.removeEventListener('click', CopyToClipBoardService.listenerCallback);
  }

  protected static listenerCallback(e: Event) {
    const target = e.target as HTMLElement | null;
    if (target) {
      const parent = target.parentElement as HTMLElement;
      if (target.classList.contains('code-badge') || parent.classList.contains('code-badge')) {
        e.preventDefault();
        e.cancelBubble = true;
        CopyToClipBoardService.copyCodeToClipboard(target);
      }
    }
  }

  protected static swapIcons($code: HTMLElement) {
    const copyIcon = $code.querySelector(CopyToClipBoardService.copyIconClass) as HTMLElement;
    const copiedIcon = $code.querySelector(CopyToClipBoardService.copiedIconClass) as HTMLElement;
    copyIcon.style.display = 'none';
    copiedIcon.style.display = 'block';

    setTimeout(() => {
      copyIcon.style.display = 'block';
      copiedIcon.style.display = 'none';
    }, 2000);
  }

  protected static copyCodeToClipboard(e: HTMLElement) {
    // walk back up to <pre> tag
    let preTag: HTMLElement | null = e;
    while (preTag !== null && preTag.tagName !== 'PRE') {
      preTag = preTag.parentElement;
    }
    if (preTag === null) {
      return;
    }

    // select the <code> tag and grab text
    const $code = preTag.querySelector('pre>code') as HTMLElement;
    const text = ($code.innerText || $code.textContent || '')
      .replace(/\n\n/g, '\n') // <table>context as text doubles all new lines
      .replace(/\t/g, ''); // <table>context adds tab add beginning of each line

    // Create a textblock and assign the text and add to document
    const el = document.createElement('textarea');
    el.value = text.trim();
    document.body.appendChild(el);
    el.style.display = 'block';

    // select the entire textblock
    // @ts-expect-error types
    if (window.document.documentMode) {
      el.setSelectionRange(0, el.value.length);
    } else {
      el.select();
    }

    // copy to clipboard
    document.execCommand('copy');

    // clean up element
    document.body.removeChild(el);

    // show the check icon (copied) briefly
    CopyToClipBoardService.swapIcons(preTag);
  }
}

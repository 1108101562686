import DynamicForm from '@/modules/@core/components/LocMarkdown/markdown-it-compiler/DynamicForm.vue';
import { CompilerOptions, TemplatePart } from '@localazy/markdown-it-compiler';

export class Injector {
  public static splitContent(content: string, options: CompilerOptions): TemplatePart[] {
    if (!options?.formBuilder) {
      return [];
    }

    const forms: string = Array.from(options.formBuilder.formsMap.keys()).join('|');
    const formIdentifier = `(${forms})`;
    const reg = `<p>\\s*{{\\s*${formIdentifier}\\s*}}\\s*<\\/p>\\s*`;
    const parts: TemplatePart[] = [];

    let match: RegExpExecArray | null;
    let splitContent: string = content;

    while ((match = new RegExp(reg, 'g').exec(splitContent)) !== null) {
      if (match.index) {
        parts.push({
          id: `id-${parts.length + 1}`,
          isContent: true,
          content: splitContent.slice(0, match.index),
        });
        parts.push({
          id: `id-${parts.length + 1}`,
          component: DynamicForm,
          props: {
            id: match[1],
            formBuilder: options.formBuilder,
          },
        });
        splitContent = splitContent.slice(match.index + match[0].length);
      }
    }

    if (splitContent.length > 0) {
      parts.push({
        id: `id-${parts.length + 1}`,
        isContent: true,
        content: splitContent,
      });
    }

    return parts;
  }
}

import { Formatting } from '@/modules/@core/models/formatting';
import { FormattingServiceOutput } from '@/modules/@core/models/formatting-service-output';

export class FormattingService {
  static applyFormattingMarks(text: string, formatting: Partial<Formatting>) {
    const { spaceMark, newLineMark, tabMark } = FormattingService.resolveFormattingMarks(text, formatting);
    const output: FormattingServiceOutput = {
      text,
      unappliedFormattings: [],
      marks: {
        spaceMark,
        newLineMark,
        tabMark,
      },
    };

    if (formatting.showFormattingMarks) {
      if (spaceMark === null || newLineMark === null || tabMark === null) {
        output.unappliedFormattings.push('showFormattingMarks');

        return output;
      }

      const formattedText = text.replace(/ /g, spaceMark).replace(/\t/g, tabMark).replace(/\n/g, `${newLineMark}\n`);

      return {
        ...output,
        text: formattedText,
      };
    }
    return output;
  }

  /** Resolve main space and newline marks or fallback to other characters if they are contained in the original text */
  protected static resolveFormattingMarks(text: string, formatting: Partial<Formatting>) {
    const zeroWidthSpace = '​';
    let spaceMark: string | null = '•';
    let tabMark: string | null = '→';
    let newLineMark: string | null = '↵';

    if (formatting.showFormattingMarks) {
      if (text.includes(spaceMark)) {
        spaceMark = '·';
      }
      if (text.includes(spaceMark)) {
        spaceMark = '◦';
      }
      if (text.includes(spaceMark)) {
        spaceMark = '●';
      }
      if (text.includes(spaceMark)) {
        spaceMark = '○';
      }
      if (text.includes(spaceMark)) {
        spaceMark = '⦿';
      }
      if (text.includes(spaceMark)) {
        spaceMark = null;
      }

      if (text.includes(newLineMark)) {
        newLineMark = '¶';
      }
      if (text.includes(newLineMark)) {
        newLineMark = '⁋';
      }
      if (text.includes(newLineMark)) {
        newLineMark = '❡';
      }
      if (text.includes(newLineMark)) {
        newLineMark = '↩';
      }
      if (text.includes(newLineMark)) {
        newLineMark = '⏎';
      }
      if (text.includes(newLineMark)) {
        newLineMark = null;
      }

      if (text.includes(tabMark)) {
        tabMark = '↦';
      }
      if (text.includes(tabMark)) {
        tabMark = '↣';
      }
      if (text.includes(tabMark)) {
        tabMark = null;
      }
    }

    if (spaceMark !== null) {
      spaceMark = `${zeroWidthSpace}${spaceMark}${zeroWidthSpace}`;
    }

    if (tabMark !== null) {
      tabMark = `${zeroWidthSpace}${tabMark}${zeroWidthSpace}`;
    }

    return {
      spaceMark,
      newLineMark,
      tabMark,
    };
  }
}

import { generateAvatarSvgString } from '@/modules/@core/functions/avatar/generate-avatar-svg-string';
import { generateIdenticon } from '@/modules/@core/functions/avatar/generate-identicon';
import { svgToPngDataUrl } from '@/modules/@core/functions/avatar/svg-to-png-data-url';

export async function generateOrganizationAvatarBrowser(
  name: string,
  randomize = false,
  widthPx = 512,
  heightPx = 512,
) {
  const svg = generateAvatarSvgString(name, generateIdenticon, randomize, widthPx, heightPx);
  return svgToPngDataUrl(svg);
}

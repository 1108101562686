import { GetAllPlansParams } from '@/modules/pricing/models/get-all-plans-params';
import { GetFeaturesForPlanOptions } from '@/modules/pricing/models/get-features-for-plan-options';
import { IPayProduct } from '@/modules/pricing/models/i-pay-product';
import { PlanData } from '@/modules/pricing/models/plan-data';
import { PlanDataFeaturesService } from '@/modules/pricing/services/plan-data-features-service';
import { ProductsService } from '@/modules/pricing/services/products-service';
import { PricingPlan, PricingPlanFeature } from '@localazy/directus-service';

export class PricingPlanUtilsService {
  public static getAllPlans(params: GetAllPlansParams, options: GetFeaturesForPlanOptions): PlanData[] {
    const {
      directusPlans,
      pricingPlans,
      pricingPlanFeatures,
      isAnnual,
      activeSubscription,
      activeSubscriptionPayPrice,
      includeFree,
    } = params;
    return directusPlans
      .filter((plan) => plan.stripe_name !== 'free' || includeFree)
      .map((plan) => ({
        plan: ProductsService.findStripeProduct(pricingPlans, plan.stripe_name) as IPayProduct,
        name: plan.stripe_name,
        title: plan.name,
        features: PlanDataFeaturesService.getFeaturesForPlan(
          {
            pricingPlanFeatures,
            directusPricingPlans: directusPlans,
            planName: plan.stripe_name,
            stripeProduct: ProductsService.findStripeProduct(pricingPlans, plan.stripe_name),
            isAnnual,
            activeSubscription,
            activeSubscriptionPayPrice,
          },
          options,
        ).filter((f) => f.showInPreview),
        showPrice: plan.show_price,
        bookCallUrl: plan.book_call_url,
        docsLink: plan.docs_link,
        hasTrial: plan.has_trial,
        priceOptions: PlanDataFeaturesService.getSourceKeyItems({
          stripeProduct: ProductsService.findStripeProduct(pricingPlans, plan.stripe_name),
          activeSubscriptionPayPrice,
          isAnnual,
        }),
      }));
  }

  public static getAllPlansWithAllFeatures(
    directusPlans: PricingPlan[],
    pricingPlans: IPayProduct[],
    pricingPlanFeatures: PricingPlanFeature[],
    isAnnual = false,
  ): PlanData[] {
    return directusPlans
      .filter((plan) => plan.stripe_name !== 'free')
      .map((plan) => ({
        plan: ProductsService.findStripeProduct(pricingPlans, plan.stripe_name) as IPayProduct,
        name: plan.stripe_name,
        title: plan.name,
        features: PlanDataFeaturesService.getAllFeaturesForPlan(
          pricingPlanFeatures,
          directusPlans,
          plan.stripe_name,
          ProductsService.findStripeProduct(pricingPlans, plan.stripe_name),
          isAnnual,
        ).filter((f) => f.showInPlanPage),
        showPrice: plan.show_price,
        bookCallUrl: plan.book_call_url,
        docsLink: plan.docs_link,
        hasTrial: plan.has_trial,
        priceOptions: PlanDataFeaturesService.getSourceKeyItems({
          stripeProduct: ProductsService.findStripeProduct(pricingPlans, plan.stripe_name),
          activeSubscriptionPayPrice: undefined,
          isAnnual,
        }),
      }));
  }
}

import { Row } from '@/modules/@core/models/row';

export function locDataTablePaginate(rows: Row[], pageSize: number, currentPage: number) {
  if (rows.length <= pageSize || pageSize <= 0 || currentPage <= 0) {
    return rows;
  }

  const start = (currentPage - 1) * pageSize;
  const end = start + pageSize;

  return [...rows].slice(start, end);
}

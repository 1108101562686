import { HandleFormattingFocusOptions } from '@/modules/@core/models/handle-formatting-focus-options';

export function handleFormattingFocus(options: HandleFormattingFocusOptions) {
  const { textarea, text, newLineMark, spaceMark, focusedCallback, emitCallback, tabMark } = options;
  // First focus on formatted text to store the cursor position
  setTimeout(() => {
    let selectionStart = 0;
    let selectionEnd = 0;
    let newLinesInSelectionRange = 0;
    let spacesInSelectionRange = 0;
    let tabsInSelectionRange = 0;
    if (textarea) {
      selectionStart = textarea.selectionStart;
      selectionEnd = textarea.selectionEnd;
      // The original text does not contain special characters for newlines, so we need to account
      // for the number of them until the cursor position
      newLinesInSelectionRange =
        newLineMark !== null ? text.substring(0, selectionEnd).split(newLineMark).length - 1 : 0;

      spacesInSelectionRange = spaceMark !== null ? text.substring(0, selectionEnd).split(spaceMark).length - 1 : 0;

      tabsInSelectionRange = tabMark !== null ? text.substring(0, selectionEnd).split(tabMark).length - 1 : 0;
    }
    // Then apply focus which replaces the formatted text
    setTimeout(() => {
      focusedCallback();
      // Lastly move cursor to where the user aimed to position
      setTimeout(() => {
        if (textarea) {
          // There are two invisible spaces around space marks, so we need to account for them
          const deductCharacterCount = newLinesInSelectionRange + spacesInSelectionRange * 2 + tabsInSelectionRange * 2;
          textarea.setSelectionRange(selectionStart - deductCharacterCount, selectionEnd - deductCharacterCount);
        }
        emitCallback();
      }, 1);
    }, 1);
  }, 1);
}

import randomMaterialColor from 'random-material-color';

export function getSeed(seedString: string, randomize = false): string {
  let seed = seedString;
  if (randomize) {
    seed += new Date().getTime().toString();
  }

  return seed;
}

export function generateColor(seedString: string, shades = ['400'], palette?: Record<string, string[]>): string {
  return randomMaterialColor.getColor({
    text: getSeed(seedString, false),
    shades,
    palette,
  });
}

export function generateRandomColor(seedString: string, shades = ['400'], palette?: Record<string, string[]>): string {
  return randomMaterialColor.getColor({
    text: getSeed(seedString, true),
    shades,
    palette,
  });
}

import { formatCurrency } from '@/modules/@core/functions/utils/format/format-currency';
import { formatNumber } from '@/modules/@core/functions/utils/format/format-number';
import { langService } from '@/modules/translations/const/lang-service';
import { PlanFeature } from '@/modules/pricing/models/plan-feature';
import { PriceOptions } from '@/modules/pricing/models/price-options';

// Add credits in the amount of current price option, this reflects payments behaviour
export function resolveFeatureLabel(planName: string, feature: PlanFeature, selectedPrice?: PriceOptions) {
  if (feature.amount) {
    return planName === 'enterprise'
      ? langService.t('resolvePrefixText.custom_source_keys')
      : langService.tc(
          'resolvePrefixText.x_source_keys',
          selectedPrice ? selectedPrice.unitAmount / 100 : feature.amount,
          {
            amount: formatNumber(selectedPrice ? selectedPrice.unitAmount / 100 : feature.amount),
          },
        );
  }
  if (feature.credits) {
    return langService.t('resolvePrefixText.x_translation_credits', {
      amount: formatCurrency(selectedPrice ? selectedPrice.unitAmount / 100 : feature.credits, {
        minimumFractionDigits: 0,
      }),
    });
  }
  return feature.name;
}

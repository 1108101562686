import { hljs } from '@/modules/plugins/const/highlight-js';

export function markdownItHighlight(md) {
  md.renderer.rules.fence = (tokens, idx) => {
    const token = tokens[idx];
    const code = token.content.trim();
    const lang = token.info ? [token.info] : undefined;
    const highlight = hljs.highlightAuto(code, lang);
    return `<pre><code class="hljs ${highlight.language || ''}">${highlight.value}</code></pre>`;
  };
}

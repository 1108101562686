import FuseBase, { IFuseOptions } from 'fuse.js';

// We want to maintain types from fuse while using only the minified version of the smaller build version
// for which there is no type declaration
export function getFuse<T>(options: IFuseOptions<T>, list: T[] = []): FuseBase<T> {
  return new FuseBase<T>(list, {
    includeScore: true,
    minMatchCharLength: 2,
    threshold: 0.2,
    includeMatches: true,
    findAllMatches: false,
    ignoreLocation: true,
    shouldSort: true,
    ...options,
  });
}

import { IconDir } from '@/modules/@core/models/icon-dir';

export function rotateClass(dir: IconDir): string {
  switch (dir) {
    case 'down':
      return 'tw-rotate-180';
    case 'right':
      return 'tw-rotate-90';
    case 'left':
      return 'tw--rotate-90';
    case 'top-right':
      return 'tw--rotate-45';
    case 'bottom-right':
      return 'tw-rotate-45';
    case 'up':
    default:
      return '';
  }
}

import { TWithChildren } from '@/modules/@core/models/t-with-children';

/**
 * The function returns all parents of an item with a given property value of a given property name.
 * A precondition for nesting a is that the data has a `children` property.
 * @returns {T[]} An array of parents.
 */
export function getParentsByProp<T>(propName: keyof T, propValue: unknown, data?: T[]): T[] {
  if (typeof data === 'undefined') {
    return [];
  }

  const parents: T[] = [];

  function findParents(nestedData: T[], potentialParents: T[] = []) {
    nestedData.forEach((item) => {
      if (item[propName] === propValue) {
        parents.push(...potentialParents, item);
      }

      const typedItem = item as TWithChildren<T>;
      if (typedItem.children) {
        findParents(typedItem.children, [...potentialParents, item]);
      }
    });
  }

  findParents(data);

  return parents;
}

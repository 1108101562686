import { EventArguments } from '@/modules/@core/models/event-arguments';
import { EventEmitterCallback } from '@/modules/@core/models/event-emitter-callback';
import { EventEmitterEvent } from '@/modules/@core/models/event-emitter-event';

// REFERENCE: https://css-tricks.com/understanding-event-emitters/
export class EventEmitterSingleton {
  protected subscribers = new Map<EventEmitterEvent, EventEmitterCallback[]>();

  protected events = new Map<EventEmitterEvent, EventArguments>();

  protected static instance: EventEmitterSingleton;

  public static getInstance(): EventEmitterSingleton {
    if (!EventEmitterSingleton.instance) {
      EventEmitterSingleton.instance = new EventEmitterSingleton();
    }

    return EventEmitterSingleton.instance;
  }

  public hasOccurred(name: string): boolean {
    return this.events.has(name);
  }

  public hasCallback(name: string, cb: (...args: any[]) => void): boolean {
    return this.subscribers.get(name)?.indexOf(cb) !== undefined;
  }

  public subscribe(name: string, cb: (...args: any[]) => void) {
    if (this.subscribers.has(name)) {
      this.subscribers.get(name)?.push(cb);
    } else {
      this.subscribers.set(name, [cb]);
    }

    return {
      unsubscribe: () => {
        if (this.subscribers.has(name)) {
          const index = this.subscribers.get(name)?.indexOf(cb);
          if (index !== undefined) {
            this.subscribers.get(name)?.splice(index, 1);
          }
        }
      },
    };
  }

  public emit(name: EventEmitterEvent, ...args: EventArguments[]): void {
    if (this.events.has(name)) {
      this.events.get(name)?.push(args);
    } else {
      this.events.set(name, [args]);
    }
    this.subscribers.get(name)?.forEach((fn) => fn(...args));
  }
}

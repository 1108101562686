import { IdenticonOptions } from '@/modules/@core/models/identicon-options';
import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-identicon-sprites';

export function generateIdenticon(seed: string, options: IdenticonOptions): string {
  return createAvatar(style, {
    seed,
    // ... other options passed
    ...options,
  });
}

import { IApiSuccessResult } from '@/modules/@core/models/i-api-success-result';
import { LanguagePair } from '@/modules/@core/models/language-pair';
import { CacheService } from '@localazy/core';
import { AxiosInstance } from 'axios';

export class CommonService {
  protected axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  async addEventForEmail(params: { email: string; event: string; token: string }): Promise<IApiSuccessResult> {
    try {
      const result = await this.axios.post('/system/email', { ...params });
      return result.data;
    } catch (e: any) {
      throw e.data;
    }
  }

  async fetchLanguagePairs(): Promise<LanguagePair[]> {
    const cacheKey = 'language-pairs';
    const cache = CacheService.get<LanguagePair[]>(cacheKey);
    if (cache) {
      return cache;
    }

    try {
      const result = await this.axios.get('/system/languages');
      CacheService.set(cacheKey, result.data);
      return result.data;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }
}

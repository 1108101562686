import { AvataaarOptions } from '@/modules/@core/models/avataaar-options';
import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-jdenticon-sprites';

/**
 * See documentation at https://avatars.dicebear.com/styles/jdenticon
 */

export function generateJdenticon(seed: string, options: AvataaarOptions): string {
  return createAvatar(style, {
    seed,
    // ... other options passed
    ...options,
  });
}

import { isNewGhostPost } from '@/modules/ghost/functions/is-new-ghost-post';

/**
 * Posts from Directus are returned as markdown and thus easily parsable through markdown-it
 * Newer posts from Ghost are in HTML, so we add the anchor links through regex
 */
export function addHeaderAnchorLinksForNewPosts(contentHTML: string) {
  const anchorIDsRegex = /<h(.) id="(.*?)">(.*?)<\/h.>/g;
  const anchorClass = 'class="markdownit-header-anchor"';

  return contentHTML.replace(anchorIDsRegex, (match, anchorLevel, anchorId, anchorContent) => {
    if (isNewGhostPost(anchorContent, anchorId, anchorLevel)) {
      const anchorHTML = `<a ${anchorClass} href="#${anchorId}">🔗</a>`;
      return `<h${anchorLevel} id="${anchorId}">${anchorContent} ${anchorHTML}</h${anchorLevel}>`;
    }
    return match;
  });
}

import iconsMap from '@/modules/@core/components/LocIcon/icons-map.json';
import { paramCase } from 'param-case';

export function resolveIconId(nameOrIdLike: string): string | undefined {
  const iconId = iconsMap[paramCase(nameOrIdLike) as keyof typeof iconsMap];
  if (iconId) {
    return iconId;
  }

  if (!Object.values(iconsMap).includes(nameOrIdLike)) {
    // eslint-disable-next-line no-console
    console.warn(`Could not find icon with name - ${nameOrIdLike}`);
  }

  return nameOrIdLike;
}

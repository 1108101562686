import { isNewGhostPost } from '@/modules/ghost/functions/is-new-ghost-post';
import { slugify } from '@/modules/ghost/functions/slugify';
import he from 'he';

/**
 * Posts created in Ghost convert emojis into their byte representation (generally 3 - 4 bytes) which are separated by '%'
 * We need to convert it into the actual characeter representation so it's parseable through slugify function
 */
export function normalizeAnchorIdForNewPosts(contentHTML: string) {
  const anchorIDsRegex = /<h(.) id="(.*?)">(.*?)<\/h.>/g;

  return contentHTML.replace(anchorIDsRegex, (match, anchorLevel, anchorId, anchorContent) => {
    if (isNewGhostPost(anchorContent, anchorId, anchorLevel)) {
      const decodedContent = he.decode(anchorContent);
      return `<h${anchorLevel} id="${slugify(decodedContent)}">${decodedContent}</h${anchorLevel}>`;
    }
    return match;
  });
}

export function getSlot(
  slots: Vue['$slots'],
  scopedSlots: Vue['$scopedSlots'],
  name = 'default',
  data?: object | (() => object),
  optional = false,
) {
  if (Object.prototype.hasOwnProperty.call(scopedSlots, name)) {
    return scopedSlots[name]!(data instanceof Function ? data() : data);
  }
  if (Object.prototype.hasOwnProperty.call(slots, name) && (!data || optional)) {
    return slots[name];
  }
  return undefined;
}

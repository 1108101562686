import { isNode } from '@localazy/core';

/**
 * Converts an svg string to base64 png using the domUrl
 * @param {string} svgText the svgtext
 * @param {number} [margin=0] the width of the border - the image size
 * will be height+margin by width+margin
 * @param {string} [fill] optionally background canvas fill
 * @return {Promise} a promise to the base64 png image
 */
export async function svgToPngDataUrl(svgText: string, margin: number = 0, fill: string = ''): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      if (isNode) {
        resolve('');
      }

      // figure out the height and width from svg text
      let match = /height="(\d+)/m.exec(svgText);
      const height = match?.[1] ? parseInt(match[1], 10) : 512;
      match = /width="(\d+)/m.exec(svgText);
      const width = match?.[1] ? parseInt(match[1], 10) : 512;
      const currentMargin = margin || 0;

      let currentSvgText = svgText;
      // it needs a namespace
      if (!/xmlns="/im.exec(svgText)) {
        currentSvgText = svgText.replace('<svg ', '<svg xmlns="http://www.w3.org/2000/svg" ');
      }

      // create a canvas element to pass through
      let canvas = document.createElement('canvas');
      canvas.width = height + currentMargin * 2;
      canvas.height = width + currentMargin * 2;
      const ctx = canvas.getContext('2d');

      // make a blob from the svg
      const svg = new Blob([currentSvgText], {
        type: 'image/svg+xml',
      });

      // create a dom object for that image
      const url = URL.createObjectURL(svg);

      // create a new image to hold it the converted type
      const img = new Image();

      // when the image is loaded we can get it as base64 url
      img.onload = () => {
        // draw it to the canvas
        if (ctx) {
          ctx.drawImage(img, currentMargin, currentMargin, canvas.width, canvas.height);
        }

        // if it needs some styling, we need a new canvas
        if (fill) {
          const styled = document.createElement('canvas');
          styled.width = canvas.width;
          styled.height = canvas.height;
          const styledCtx = styled.getContext('2d');
          if (styledCtx) {
            styledCtx.save();
            styledCtx.fillStyle = fill;
            styledCtx.fillRect(0, 0, canvas.width, canvas.height);
            styledCtx.strokeRect(0, 0, canvas.width, canvas.height);
            styledCtx.restore();
            styledCtx.drawImage(canvas, 0, 0, canvas.width, canvas.height);
          }
          canvas = styled;
        }
        // we don't need the original anymore
        URL.revokeObjectURL(url);
        // now we can resolve the promise, passing the base64 url
        resolve(canvas.toDataURL());
      };

      // load the image
      img.src = url;
    } catch (err) {
      reject(Error(`Failed to convert svg to png ${String(err)}`));
    }
  });
}

import { get } from 'lodash-es';

export function locDataTableDefaultComparator(sortAttribute: string | undefined, sortBy: string, a: any, b: any) {
  let aVal = a[sortBy];
  let bVal = b[sortBy];

  if (sortAttribute) {
    aVal = get(aVal, sortAttribute);
    bVal = get(bVal, sortAttribute);
  }

  if (Number.isFinite(aVal) && Number.isFinite(bVal)) {
    return aVal - bVal;
  }
  aVal = aVal !== null && aVal !== undefined ? aVal.toString().toLowerCase() : '';
  bVal = bVal !== null && bVal !== undefined ? bVal.toString().toLowerCase() : '';
  if (aVal < bVal) return -1;
  if (aVal > bVal) return 1;
  return 0;
}

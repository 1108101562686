import { SortOrder } from '@/modules/@core/enums/sort-order';

export function sortIconClass(
  key: string,
  sortBy: string | null,
  sortOrder: SortOrder,
  hoveredOverKey: string,
): string {
  const isHoveredOver = hoveredOverKey === key;
  if (sortBy === key && sortOrder !== SortOrder.UNSORTED) {
    return 'tw-fill-primary';
  }

  if (isHoveredOver) {
    return 'tw-fill-primary-lighten-2';
  }

  return 'tw-invisible';
}

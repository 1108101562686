/** Reference: https://css-tricks.com/converting-color-spaces-in-javascript/ */

export const colorsService = {
  getHSLValues: (hsl: string) => {
    const [h, s, l] = hsl
      .replace('hsl(', '')
      .replace(')', '')
      .split(',')
      .map((value) => Number(value.replace('%', '')));
    return { h, s, l };
  },

  hexToHSL: (hex: string) => {
    // Convert hex to RGB first
    let r = 0;
    let g = 0;
    let b = 0;
    if (hex.length === 4) {
      r = Number(`0x${hex[1]}${hex[1]}`);
      g = Number(`0x${hex[2]}${hex[2]}`);
      b = Number(`0x${hex[3]}${hex[3]}`);
    } else if (hex.length === 7) {
      r = Number(`0x${hex[1]}${hex[2]}`);
      g = Number(`0x${hex[3]}${hex[4]}`);
      b = Number(`0x${hex[5]}${hex[6]}`);
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    const cmin = Math.min(r, g, b);
    const cmax = Math.max(r, g, b);
    const delta = cmax - cmin;
    let hue = 0;
    let saturation = 0;
    let lightness = 0;

    if (delta === 0) hue = 0;
    else if (cmax === r) hue = ((g - b) / delta) % 6;
    else if (cmax === g) hue = (b - r) / delta + 2;
    else hue = (r - g) / delta + 4;

    hue = Math.round(hue * 60);

    if (hue < 0) hue += 360;

    lightness = (cmax + cmin) / 2;
    saturation = delta === 0 ? 0 : delta / (1 - Math.abs(2 * lightness - 1));
    saturation = +(saturation * 100).toFixed(1);
    lightness = +(lightness * 100).toFixed(1);

    // return `hsl(${h},${s}%,${l}%)`
    return {
      hsl: `hsl(${hue},${saturation}%,${lightness}%)`,
      hue,
      saturation,
      lightness,
    };
  },

  HSLToHex: (payload: string | { h: number; s: number; l: number }) => {
    const { h, s, l } = typeof payload === 'string' ? colorsService.getHSLValues(payload) : payload;
    const fractionSaturation = s / 100;
    const fractionLightness = l / 100;

    const c = (1 - Math.abs(2 * fractionLightness - 1)) * fractionSaturation;
    const x = c * (1 - Math.abs((h / 60) % 2) - 1);
    const m = fractionLightness - c / 2;
    let r = 0;
    let g = 0;
    let b = 0;

    if (h >= 0 && h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (h >= 60 && h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (h >= 120 && h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (h >= 180 && h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (h >= 240 && h < 300) {
      r = x;
      g = 0;
      b = c;
    } else if (h >= 300 && h < 360) {
      r = c;
      g = 0;
      b = x;
    }
    // Having obtained RGB, convert channels to hex
    let rHex = Math.round((r + m) * 255).toString(16);
    let gHex = Math.round((g + m) * 255).toString(16);
    let bHex = Math.round((b + m) * 255).toString(16);

    // Prepend 0s, if necessary
    if (rHex.length === 1) rHex = `0${rHex}`;
    if (gHex.length === 1) gHex = `0${gHex}`;
    if (bHex.length === 1) bHex = `0${bHex}`;

    return `#${rHex}${gHex}${bHex}`;
  },
};

export function isDescendantNode(parent: Element | null, child: Element | null) {
  if (!!parent && !!child) {
    let node = child.parentNode;
    while (node !== null) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }
  }
  return false;
}

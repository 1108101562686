export class EnvService {
  protected static mode: string = 'production';
  protected static nodeEnv: string = 'production';
  protected static directusConnectorServiceUrl: string = '';
  protected static ghostDirectusUrl: string = '';
  protected static ghostUrl: string = 'https://ghost.localazy.com';
  // Random letters of length exactly 26, so the library doesn't complain if we don't have a key
  protected static ghostContentApi: string = 'ababababababababababababab';

  public static updateMode(mode: string) {
    EnvService.mode = mode;
  }

  public static updateNodeEnv(nodeEnv: string) {
    EnvService.nodeEnv = nodeEnv;
  }

  public static updateDirectusConnectorServiceUrl(url: string) {
    EnvService.directusConnectorServiceUrl = url;
  }

  public static updateGhostDirectusUrl(url: string) {
    EnvService.ghostDirectusUrl = url;
  }

  public static updateGhostUrl(url: string) {
    EnvService.ghostUrl = url;
  }

  public static updateGhostContentApi(api: string) {
    EnvService.ghostContentApi = api;
  }

  public static isProductionMode(): boolean {
    return EnvService.mode === 'production';
  }

  public static isDevelopmentMode(): boolean {
    return EnvService.mode === 'development';
  }

  public static isProductionNodeEnv(): boolean {
    return EnvService.nodeEnv === 'production';
  }

  public static isDevelopmentNodeEnv(): boolean {
    return EnvService.nodeEnv === 'development';
  }

  public static getDirectusConnectorServiceUrl(): string {
    return EnvService.directusConnectorServiceUrl;
  }

  public static getGhostDirectusUrl(): string {
    return EnvService.ghostDirectusUrl;
  }

  public static getGhostUrl(): string {
    return EnvService.ghostUrl;
  }

  public static getGhostContentApi(): string {
    return EnvService.ghostContentApi;
  }
}

import { CACHE_KEY } from '@/modules/pricing/const/cache-key';
import { IPayProduct } from '@/modules/pricing/models/i-pay-product';
import { CacheService } from '@localazy/core';
import { AxiosInstance } from 'axios';

export class ProductsService {
  protected axios!: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  async getAll(): Promise<IPayProduct[]> {
    try {
      const cached = CacheService.get<IPayProduct[]>(CACHE_KEY);
      if (cached) {
        return cached;
      }
      const result = await this.axios.get('/products');
      CacheService.set(CACHE_KEY, result.data);
      return result.data;
    } catch (e: any) {
      throw e.data ? e.data : e;
    }
  }

  public static findStripeProduct(pricingPlans: IPayProduct[], planName: string): IPayProduct | undefined {
    return pricingPlans.find((plan) => plan.metadata.name.toLowerCase() === planName.toLowerCase());
  }
}

import { EnvService } from '@/modules/@core/services/env-service';
import { ghostApi } from '@/modules/ghost/const/ghost-api';
import { ghostWebhooksApi } from '@/modules/ghost/const/ghost-webhooks-api';
import { searchInGhostFactory } from '@/modules/ghost/functions/search-in-ghost-factory';

import { parseHtmlContent } from '@/modules/ghost/functions/parse-html-content';

import { IBaseGhostPost } from '@/modules/ghost/models/i-base-ghost-post';
import { IBlogPost } from '@/modules/ghost/models/i-blog-post';
import { IBlogPostDetail } from '@/modules/ghost/models/i-blog-post-detail';
import { IGhostPost } from '@/modules/ghost/models/i-ghost-post';
import { IGhostPostDetail } from '@/modules/ghost/models/i-ghost-post-detail';
import { IGhostPostDetailBase } from '@/modules/ghost/models/i-ghost-post-detail-base';
import { IGhostPosts } from '@/modules/ghost/models/i-ghost-posts';
import { ITag } from '@/modules/ghost/models/i-tag';
import { CtaService, DictionaryService, DirectusTagService } from '@localazy/directus-service';
import { Author, Params, PostsOrPages, Tag } from '@tryghost/content-api';

const fetchDictionaryForPost = async (post: IBaseGhostPost) => {
  const dictTags = post.tags.filter((tag) => tag.slug.startsWith('term-'));
  return Promise.all(
    dictTags.map((tag) => {
      const directusId = tag.slug.substr(tag.slug.indexOf('-') + 1);
      return DictionaryService.fetchDictionaryTermId(directusId);
    }),
  );
};

const fetchCTAForPost = async (post: IBaseGhostPost) => {
  const ctaTag = post.tags.find((tag) => tag.slug.startsWith('cta-'));

  if (ctaTag) {
    const directusId = ctaTag.slug.substr(ctaTag.slug.indexOf('-') + 1);

    return CtaService.fetchCTAById(directusId);
  }

  return null;
};

const convertGhostToDirectusForm = (post: IGhostPost): IBlogPost => ({
  id: post.id,
  owner: {
    id: post.primary_author.id,
    first_name: post.primary_author.name || '',
    last_name: '',
    slug: post.primary_author.slug || '',
    avatar: post.primary_author.profile_image || '',
  },
  created_by: null,
  sort: null,
  title: post.title,
  slug: post.slug,
  modified_on: post.updated_at || '',
  created_on: post.published_at || post.created_at,
  pinned: !!post.featured,
  badge: null,
  priority: '0.7',
  main_image: post.feature_image ? post.feature_image : null,
  status: 'published', // Ghost Content API does not fetch drafts,
  tags: post.plainTags,
  dictionary: post.dictionary,
  reading_time: post.reading_time || 0,
  og_title: post.og_title || '',
  og_description: post.og_description || '',
  og_image: post.og_image || '',
});

const convertGhostDetailToDirectusForm = (post: IGhostPostDetail): IBlogPostDetail => ({
  ...convertGhostToDirectusForm(post),
  excerpt: post.excerpt,
  content: parseHtmlContent(post.html || ''),
  meta_title: post.meta_title || '',
  meta_description: post.meta_description || '',
  canonical: post.canonical_url || '',
  cta: post.cta,
  fullGhostPost: post,
});

export class BaseGhostService {
  static DEFAULT_SORT = ['featured DESC', 'published_at DESC', 'updated_at DESC'];

  static async expandAndConvertToDirectus(post: IGhostPostDetail) {
    return convertGhostDetailToDirectusForm(await this.expandGhostPost(post));
  }

  static convertSingleGhostTagToDirectus = (tag: Tag, tagsOnIndexPage: ITag[]): ITag => ({
    id: tag.id,
    status: 'published',
    sort: null,
    label: tag.name || '',
    slug: tag.slug,
    on_index_page: tagsOnIndexPage.some((t) => t.slug === tag.slug),
  });

  static convertGhostTagsToDirectus = (post: IBaseGhostPost, tagsOnIndexPage: ITag[]): ITag[] =>
    post.tags
      .filter((tag) => !/\[[A-Za-z0-9]+\]/.exec(tag.name))
      .map((tag) => BaseGhostService.convertSingleGhostTagToDirectus(tag, tagsOnIndexPage));

  static async fetchSinglePost(slug: string, params: Params = {}) {
    const ghostPost = await this.fetchSinglePostResolver(slug, params);
    const extendedGhostPost: IGhostPostDetail = await BaseGhostService.expandGhostPost(ghostPost);
    return convertGhostDetailToDirectusForm(extendedGhostPost);
  }

  /** Don't use unless you absolutely need the detailed data in fetched list of posts */
  static async fetchPostsWithAllAttributes(params: Params = {}) {
    const [result, tagsOnIndexPage] = await Promise.all([
      this.fetchPostsResolver(true, params),
      DirectusTagService.fetchTagsOnBlogIndexPage(),
    ]);

    const ghostPosts: IGhostPosts = {
      meta: result.meta,
      posts: result.map((post) => {
        const ghostPost = post as IGhostPostDetail;
        return BaseGhostService.mockExpandGhostPost(ghostPost, tagsOnIndexPage);
      }),
    };
    return ghostPosts.posts.map((post) => convertGhostDetailToDirectusForm(post as IGhostPostDetail));
  }

  static async fetchPosts(params: Params = {}) {
    const [result, tagsOnIndexPage] = await Promise.all([
      this.fetchPostsResolver(false, params),
      DirectusTagService.fetchTagsOnBlogIndexPage(),
    ]);
    const ghostPosts: IGhostPosts = {
      meta: result.meta,
      posts: result.map((post) => {
        const ghostPost = post as IGhostPost;
        return BaseGhostService.mockExpandGhostPost(ghostPost, tagsOnIndexPage);
      }),
    };
    return ghostPosts.posts.map((post) => convertGhostToDirectusForm(post));
  }

  static async fetchAllPosts(params: Params = {}) {
    return this.fetchPosts({
      limit: 'all',
      ...params,
    });
  }

  static async searchForPosts(lookup: string) {
    const searchInGhost = searchInGhostFactory();
    const [posts, tagsOnIndexPage] = await Promise.all([
      searchInGhost.search(lookup),
      DirectusTagService.fetchTagsOnBlogIndexPage(),
    ]);
    const extendedGhostPosts = (posts as IGhostPost[]).map((post) => {
      const ghostPost = post as IBaseGhostPost;
      return BaseGhostService.mockExpandGhostPost(ghostPost, tagsOnIndexPage);
    });
    return extendedGhostPosts.map((post) => convertGhostToDirectusForm(post));
  }

  static async fetchSingleTag(slug: string, params: Params = {}) {
    const [tag, tagsOnIndexPage] = await Promise.all([
      ghostApi().tags.read(
        { slug },
        {
          formats: ['html'],
          ...params,
        },
      ),
      DirectusTagService.fetchTagsOnBlogIndexPage(),
    ]);
    return BaseGhostService.convertSingleGhostTagToDirectus(tag, tagsOnIndexPage);
  }

  static async fetchAllTags(params: Params = {}): Promise<ITag[]> {
    const [tags, tagsOnIndexPage] = await Promise.all([
      ghostApi().tags.browse({
        formats: ['html'],
        limit: 'all',
        ...params,
      }),
      DirectusTagService.fetchTagsOnBlogIndexPage(),
    ]);
    return tags.map((tag) => BaseGhostService.convertSingleGhostTagToDirectus(tag, tagsOnIndexPage));
  }

  static async fetchSingleAuthor(slug: string, params: Params = {}): Promise<Author> {
    const author = await ghostApi().authors.read(
      { slug },
      {
        formats: ['html'],
        ...params,
      },
    );
    return author;
  }

  /**
   * Expand the base ghost post with data from Directus
   */
  protected static async expandGhostPost(post: IGhostPostDetailBase) {
    const [dictionary, cta, tagsOnIndexPage] = await Promise.all([
      fetchDictionaryForPost(post),
      fetchCTAForPost(post),
      DirectusTagService.fetchTagsOnBlogIndexPage(),
    ]);

    const extendedGhostPost: IGhostPostDetail = {
      ...post,
      dictionary,
      cta,
      plainTags: BaseGhostService.convertGhostTagsToDirectus(post, tagsOnIndexPage),
    };
    return extendedGhostPost;
  }

  /**
   * In many cases, information about dictionary and CTA is redundant
   * Generally only required when rendering detail of the post
   */
  protected static mockExpandGhostPost(post: IBaseGhostPost, tagsOnIndexPage: ITag[]) {
    const extendedGhostPost: IGhostPost = {
      ...post,
      dictionary: [],
      plainTags: BaseGhostService.convertGhostTagsToDirectus(post, tagsOnIndexPage),
    };
    return extendedGhostPost;
  }

  protected static async fetchSinglePostResolver(slug: string, params: Params = {}): Promise<IGhostPostDetail> {
    const allParams: Params = {
      include: ['tags', 'authors'],
      formats: ['html'],
      ...params,
    };
    if (EnvService.isDevelopmentNodeEnv() || EnvService.isDevelopmentMode()) {
      const queryString = Object.entries(allParams)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      const result = await ghostWebhooksApi().get(`/ghost/post/${slug}?${queryString}`);
      return result.data;
    }
    return (await ghostApi().posts.read({ slug }, allParams)) as IGhostPostDetail;
  }

  /**
   * Client SDK cannot fetch posts in draft status. Therefore we use ghost connector api to fetch them for development.
   * In production mode, we use the client SDK to fetch posts.
   */
  protected static async fetchPostsResolver(fetchAllFields: boolean, params: Params = {}): Promise<PostsOrPages> {
    let allParams: Params = {
      include: ['tags', 'authors'],
    };
    if (!fetchAllFields) {
      allParams.fields = 'id,primary_tag,primary_author,slug,title,feature_image,published_at,reading_time';
    }
    allParams = {
      ...allParams,
      ...params,
    };
    if (EnvService.isDevelopmentNodeEnv() || EnvService.isDevelopmentMode()) {
      const queryString = Object.entries(allParams)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      const result = await ghostWebhooksApi().get(`/ghost/posts?${queryString}`);
      return result.data;
    }
    return ghostApi().posts.browse(allParams);
  }
}

import { ExtendedVNode } from '@/modules/vue/models/extended-v-node';
import { IObserveVNodeDirective } from '@/modules/vue/models/i-observe-v-node-directive';
import { IntersectExtendedHtmlElement } from '@/modules/vue/models/intersect-extended-html-element';
import { VNode } from 'vue';

export function unbind(el: IntersectExtendedHtmlElement, binding: IObserveVNodeDirective, vnode: VNode) {
  const extendedVNode = vnode as ExtendedVNode;

  const observe = el._observe?.[extendedVNode.context._uid];
  if (!observe) return;

  observe.observer.unobserve(el);

  delete el._observe![extendedVNode.context._uid];
}

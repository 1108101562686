import { AvataaarOptions } from '@/modules/@core/models/avataaar-options';
import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-avataaars-sprites';
//  import { Eyebrow, Eyes, Mouth } from '@dicebear/avatars-avataaars-sprites/dist/options.d';

/**
 * See documentation at https://avatars.dicebear.com/styles/avataaars
 */
const allowedEyesStyles = ['close', 'default', 'happy', 'hearts', 'side', 'squint', 'surprised', 'wink', 'winkWacky'];
const allowedEyebrowStyles = [
  'default',
  'defaultNatural',
  'flat',
  'flatNatural',
  'raised',
  'raisedExcited',
  'raisedExcitedNatural',
  'unibrow',
  'unibrowNatural',
  'upDown',
  'upDownNatural',
];
const allowedMountStyles = ['default', 'eating', 'grimace', 'serious', 'smile', 'tongue', 'twinkle'];

function setMouthStyles(options: AvataaarOptions) {
  return options.mouth === undefined ? allowedMountStyles : options.mouth;
}

function setEyesStyles(options: AvataaarOptions) {
  return options.eyes === undefined ? allowedEyesStyles : options.eyes;
}

function setEyebrowStyles(options: AvataaarOptions) {
  return options.eyebrow === undefined ? allowedEyebrowStyles : options.eyebrow;
}

export function generateAvataaar(seed: string, options: AvataaarOptions): string {
  const computedOptions = options;
  computedOptions.mouth = setMouthStyles(options);
  computedOptions.eyes = setEyesStyles(options);
  computedOptions.eyebrow = setEyebrowStyles(options);

  return createAvatar(style, {
    seed,
    // ... other options passed
    ...computedOptions,
  });
}

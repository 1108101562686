import { EnvService } from '@/modules/@core/services/env-service';
import { ArticleFeedbackDTO } from '@/modules/connector/models/article-feedback-dto';
import { ArticleFeedbackUpdateDTO } from '@/modules/connector/models/article-feedback-update-dto';
import { SearchResult } from '@localazy/directus-service';
import axios, { AxiosInstance } from 'axios';

export class WebConnectorService {
  config = {
    headers: {
      // TODO: add some authorization token
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
  };

  request: AxiosInstance;

  constructor() {
    this.request = axios.create({
      // Must be relative path in development, otherwise devProxy won't work
      // See https://github.com/vuejs/vue-cli/issues/3423
      baseURL: EnvService.getDirectusConnectorServiceUrl(),
      ...this.config,
    });
  }

  /**
   * Fulltext search in Localazy Documentation
   * @param value search value
   * @param maxCount maximum of items returned
   */
  async searchInDocs(value: string, maxCount?: number): Promise<SearchResult[]> {
    try {
      let url = `/documentation/search?search=${value}`;
      if (maxCount) {
        url += `&maxCount=${maxCount}`;
      }
      const result = await this.request.get(url);
      return result.data;
    } catch (e: any) {
      return e.data || e;
    }
  }

  async submitArticleFeedback(body: ArticleFeedbackDTO): Promise<{ result: boolean; id: string }> {
    try {
      const result = await this.request.post('/feedback', body);
      return result.data;
    } catch (e: any) {
      return e.data || e;
    }
  }

  async updateArticleFeedback(body: ArticleFeedbackUpdateDTO): Promise<{ result: boolean; id: string }> {
    try {
      const result = await this.request.patch('/feedback', body);
      return result.data;
    } catch (e: any) {
      return e.data || e;
    }
  }
}

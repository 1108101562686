import { IEventEmitterEvents } from '@/modules/@core/models/i-event-emitter-events';

// REFERENCE: https://css-tricks.com/understanding-event-emitters/
export class EventEmitter {
  public events: IEventEmitterEvents;

  constructor(events?: IEventEmitterEvents) {
    this.events = events || {};
  }

  public subscribe(name: string, cb: (..._args: any[]) => any) {
    (this.events[name] || (this.events[name] = [])).push(cb);

    return {
      // TODO document what this bitshift does
      unsubscribe: () => this.events[name]?.splice(this.events[name].indexOf(cb) >>> 0, 1),
    };
  }

  public emit(name: string, ...args: unknown[]): void {
    (this.events[name] || []).forEach((fn) => fn(...args));
  }
}

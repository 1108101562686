export class LocalStorageService {
  static setItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  static getItem(key: string): string | null {
    const data = localStorage.getItem(key);
    return data;
  }

  static removeItem(key: string) {
    localStorage.removeItem(key);
  }

  static hasKey(key: string): boolean {
    return localStorage.getItem(key) !== null;
  }

  static flush() {
    localStorage.clear();
  }
}

import { EnvService } from '@/modules/@core/services/env-service';
import { addHeaderAnchorLinksForNewPosts } from '@/modules/ghost/functions/add-header-anchor-links-for-new-posts';
import { normalizeAnchorIdForNewPosts } from '@/modules/ghost/functions/normalize-anchor-id-for-new-posts';

/**
 * Ghost turns relative links into absolute ones, prefixing them with Ghost site's URL
 * For media uploaded to Ghost this is desirable. However, for relative links on our web
 * it's undesirable since those urls don't exist.
 */
export function parseHtmlContent(contentHTML: string) {
  const ghostURL = EnvService.getGhostUrl();
  const relativeLinksRegex = new RegExp(`href="${ghostURL}/(.*?)"`, 'g');
  const normalizedAnchorsHTML = normalizeAnchorIdForNewPosts(contentHTML);

  return addHeaderAnchorLinksForNewPosts(normalizedAnchorsHTML).replace(relativeLinksRegex, 'href="/$1"');
}
